<template>
  <div class="error-page">404</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.error-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #016b70;
  font-size: 25rem;
  font-weight: 700;
  letter-spacing: 2.5rem;
}
</style>